
import * as vercelRuntime$ZuLDTVqgyV from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.1.0/node_modules/@nuxt/image/dist/runtime/providers/vercel'
import * as imgixRuntime$gIDQdXJxPg from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.1.0/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as ipxRuntime$ZW2WymYafT from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.1.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as shopifyRuntime$MIL7ftO9B9 from '/vercel/path0/providers/shopify.ts'
import * as unsplashRuntime$r04FGohVft from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.1.0/node_modules/@nuxt/image/dist/runtime/providers/unsplash'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$ZuLDTVqgyV, defaults: {} },
  ['imgix']: { provider: imgixRuntime$gIDQdXJxPg, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$ZW2WymYafT, defaults: undefined },
  ['shopify']: { provider: shopifyRuntime$MIL7ftO9B9, defaults: {"baseURL":"https://cdn.shopify.com"} },
  ['unsplash']: { provider: unsplashRuntime$r04FGohVft, defaults: undefined }
}
        